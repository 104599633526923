import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";

const Index = () => {
    return (
        <Layout>
            <MetaComponents
                title="Welche Nebenkosten entstehen bei einem Kredit? | miracl"
                description="Einen Kredit aufzunehmen, bedeutet nicht nur Geld zu leihen, das man danach zurückzahlt. Er ist mit vielen Nebenkosten verbunden um die notwendigen Prozesse abzuwickeln."
            />
            <GreyHeader title="Welche Nebenkosten entstehen bei einem Kredit?" />
            <Article hasMarginTop hasColumns>
                <p>
                    Unabhängig davon, ob und wie die Zinsen sich verändern werden, ist der Kaufpreis der Immobilie nicht
                    alles, was von dir an Geld aufgebracht werden muss.
                </p>

                <p>
                    Sobald man einen Immobilienkredit aufnimmt, muss man mit Nebenkosten rechnen. Die wichtigsten Kosten
                    sind dabei die:
                </p>

                <ul>
                    <li>
                        <b>Maklerprovision:</b>

                        <p>
                            Die <u>Maklerprovision</u> beträgt ca. <b>3,6 %</b> des Kaufpreises und kann nur in seltenen
                            Fällen eingespart werden, wenn du keine Makler:in benötigst.
                        </p>
                        <br />
                    </li>

                    <li>
                        <b>Grundbucheintragung:</b>

                        <p>
                            Für die <u>Eintragung des Eigentumsrechtes</u> in das Grundbuch ist ebenfalls eine Gebühr zu
                            zahlen. Hier zahlst du zumindest <b>1,1 %</b> des Kaufpreises und eine Eingabegebühr von{" "}
                            <b>44 Euro</b>. Wenn der Kauf mithilfe der Bank finanziert wird, sind darüber hinaus noch{" "}
                            <b>1,2 %</b> des <u>Pfandbetrags</u> zu bezahlen.
                        </p>
                        <br />
                    </li>

                    <li>
                        <b>Notar-, Anwalts und Kaufvertragserrichtungskosten:</b>
                        <p>
                            Die Notar- oder Anwaltskosten sind ebenfalls unvermeidbar. Diese betragen ca. <b>2 %</b> des
                            Kaufpreises Miracl übernimmt für dich die Bürokratie mit den Notar- und Anwaltsterminen.
                            <a href={"/"}> Mehr dazu kannst du hier lesen...</a>
                        </p>
                        <br />
                    </li>

                    <li>
                        <b>Grunderwerbssteuer:</b>
                        <p>
                            Für jede Liegenschaft ist außerdem eine <u>Grunderwerbssteuer</u> von <b>3,5 %</b> des
                            Grundstückswerts zu bezahlen.
                        </p>
                        <br />
                    </li>

                    <li>
                        <b>Finanzierungskosten:</b>
                        <p>
                            <u>Finanzierungskosten</u>, wenn du einen Kredit bei einer Bank oder einer Bausparkasse
                            aufnimmst. Hier kannst du mit max. <b>3 %</b> des Kreditvolumens rechnen
                        </p>
                        <br />
                    </li>
                </ul>
            </Article>
            {/*TODO: Add Sample Calculation here:*/}
            <TipsCarousel ignoreId={"welcheNebenkostenBeimKredit"} title={"Andere Tips"} />
            <BreadcrumbList page={"welcheNebenkostenBeimKredit"}></BreadcrumbList>
            <ArticleStructuredData
                page={"welcheNebenkostenBeimKredit"}
                heading={"Welche Nebenkosten entstehen bei einem Kredit?"}
                description="Einen Kredit aufzunehmen, bedeutet nicht nur Geld zu leihen, das man danach zurückzahlt. Er ist mit vielen Nebenkosten verbunden um die notwendigen Prozesse abzuwickeln."
                datePublished="2022-09-12"
                dateModified="2022-09-12" 
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
